import React from 'react';
import { StyledStaticImageContainer } from './_shared/styled-image-container';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Img from "gatsby-image"

const StyledRoundImage = styled(StyledStaticImageContainer)`
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -o-border-radius: 50% !important;
    border-radius: 50% !important;

    .gatsby-image-wrapper {
        overflow: hidden !important;
      }

    .gatsby-image-wrapper:before {
        -webkit-border-radius: 50% !important;
        -moz-border-radius: 50% !important;
        -o-border-radius: 50% !important;
        border-radius: 50% !important;
      }

`;

const StyledRoundImg = styled(Img)`
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -o-border-radius: 50% !important;
    border-radius: 50% !important;

    &:hover{
        opacity: 50%;
        transition: all ease var(--transition-fast);
    }
`;


const RoundedImage = ({data,r}) => {
    const images = data;

    var r = Math.floor(Math.random() * 3);


    console.log(r);
    const image = images[r].frontmatter.cover_image ? images[r].frontmatter.cover_image.childImageSharp.fluid : null;
    const link = `/projects` + images[r].fields.slug;
    return (
        <StyledRoundImage>
            <a href={link}>
                <StyledRoundImg fluid = {image}/>
            </a>
        </StyledRoundImage>
    );
};

RoundedImage.propTypes = {
    data: PropTypes.array.isRequired,
};


export default RoundedImage;